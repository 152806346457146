import React, { useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const ProductContext = React.createContext();;

const ProductProvider = (props) => {
    const navigate=useNavigate();
    const [loggedUser, setLoggedUser] = useState({ username: 'Guest', email: '', phoneno: '', startDate: '', address: '', name: '', clientID:'', orgName:'', profile:'', isAuthenticated: false, gstNo:'' });
    const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
    const [actionClick, setActionClick]=useState('');
    const [loginValidated, setLoginValidated] = useState(false);

    const alertClose=()=>{
      setAlertDetails({
          ...alertDetails,
          style:"",
          open:false,
          message:""
      });
  }

    const validateLogin = async () => {
        try {
          await fetchAuthSession()
          .then((user) => {
             console.log("User : ", user);
             //const familyName=user.attributes.family_name;
             const {email, profile, name, nickname,family_name, birthdate, phone_number, gender, website }= user.tokens.idToken.payload;
              setLoggedUser({
                ...loggedUser,
                name:name,
                email:email,
                profile:profile,
                DOB:birthdate,
                website:website,
                nickname:nickname,
                gender:gender,
                nickname:family_name,
                phone_number:phone_number,
                isAuthenticated:true,
                    });
                    navigate("/");
            })
            .catch((error) => {
                //console.log("ERror : ", error);
              setLoggedUser({
                ...loggedUser,
                username: '',
                isAuthenticated: false,
                email:'',
                phoneno:'',
                address:'',
                startDate:'',
                clientID:'',
                profile:'',
                orgName:'',
                gstNo:''
              });
            })
        } catch (error) {
          console.log(error);
        }
      }
    
 
    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            validateLogin,
            alertDetails, 
            setAlertDetails,
            alertClose,
            actionClick,
            setActionClick,
            loginValidated, 
            setLoginValidated
        }}>
            {props.children}
        </ProductContext.Provider>
    )
}

export { ProductProvider, ProductContext };