import React, {useContext, useEffect} from 'react';
//import logo from './logo.svg';
import './App.css';
import { ProductContext } from './ProductProvider';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import {Helmet} from "react-helmet-async";
import Navbar3 from './Components/Narbar3/Navbar3';
import SubscriptionMain from './pages/Subscription/SubscriptionMain';
import ContactMain from './pages/Contact/ContactMain';
import AboutMain from './pages/About/AboutMain';
import PublicationsMain from './pages/Publications/PublicationsMain';
import Privacy from './pages/Policies/Privacy';
import Disclaimer from './pages/Policies/Disclaimer';
import ViewArchives from './pages/Journal/ViewArchives';
import MyProfile from './pages/dashboard/MyProfile';
import ForgotPassword from './pages/auth/ForgotPassword';
import SignInMFA from './pages/auth/SignInMFA';

import 'bootstrap/dist/css/bootstrap.css';
/*Fonts */
import '../src/App.css';
import "./Fonts/Freehand.ttf";
import "./Fonts/BRLNSDB.TTF";
import "./Fonts/MisterEarlBTRegular.TTF";
import "./Fonts/BernhandTango.ttf";
import "./Fonts/Calligraph421BT.TTF";
import "./Fonts/domboldBT.ttf";
import "./Fonts/GilSansMTCondBold.TTF";
import "./Fonts/GilSansMTCondensed.TTF";
import "./Fonts/HARNGTON.TTF";
import "./Fonts/HoboStd.otf";
import "./Fonts/JohnHandyLET.TTF";
import "./Fonts/ParkAvenue.TTF";
import "./Fonts/TektonPro-Bold.otf";
import "./Fonts/TektonPro-BoldCond.otf";


function App() {
  const { validateLogin} = useContext(ProductContext);

  useEffect(() => {
    validateLogin();
  }, []);

  return (
    <>
    <Helmet>
      <title>VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
    <Navbar3 />
    <main style={{paddingTop: '70px'}} >
    <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/subscription" element={<SubscriptionMain />} />
          <Route path="/contact" element={<ContactMain />} />
          <Route path="/publication" element={<PublicationsMain />} />
          <Route path="/archives" element={<ViewArchives />} />
          <Route path="/about" element={<AboutMain />} />
          <Route path="/privacy_policy" element={<Privacy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/signin" element={<SignInMFA />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="*" element={<Navigate to="/" replace />}/>
    </Routes>
    </main>
    </>
  );
}

export default App;
