import React, { useEffect, useContext, useState } from 'react';
//import { Col, Row, Card, Form } from 'react-bootstrap';
import {Alert} from 'react-bootstrap';
import { Col, Row, Card, Form } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import {Helmet} from 'react-helmet-async';
import { phoneCode, journalCategory } from "../../ProData";
import Preloader from '../../Components/preloader/Preloader';

//import RegistrationForm from '../Registrations/RegistrationForm';
const config = require('../../config.json');


export default function MyProfile() {
  //const history=useHistory();
  const { loggedUser, alertDetails, setAlertDetails, alertClose} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [subscriberDetails, setsubscriberDetails] = useState({subscriberID:'', title:'Mr',subscriberName:'', areaCode:'+91',contactNo:'', emailID:'', houseName:'', addressStreet:'', addressCity:'', addressState:'',
  addressPincode:'', country:'', copies:'', categoryID:'',subStatus:'', regDate:'', startDate:'', endDate:''});
  
  //const [imageFile, setImageFile] = useState('');
 // const [profileImage,setProfileImage]=useState('https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg');

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      setViewOnly(true);
      fetchMyProfile();
      window.scrollTo(0,0);
    }
  },[loggedUser])

  const fetchMyProfile=async()=>{
    setLoaded(false);
    try{
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const searchOption="SubscriberID";
          await axios.get(`${config.api.invokeUrl}/subscribers/globalsearch?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", loggedUser);
            if(response.data.length>0)
            {
                setsubscriberDetails(response.data[0]);
                //setProfileDetails(response.data[0]);
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Profile loaded Successfully!"
              });  
              setLoaded(true);
            }
            setLoaded(true);
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });      
        console.log("error :", error);  
        setLoaded(true);
      })

    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    console.log("error :", error);  
    setLoaded(true);
  }
}

  return (
    <div>
    <Helmet>
      <title>My Profile - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="My Profile - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="My Profile - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

    {!loaded && <Preloader show={loaded ? true : false} />}
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <h4>My Profile</h4>
          <div >
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        
        <Row>
            <Col md={2} className="mb-3">
              Title
              <Form.Group id="title">
                <Form.Select value={subscriberDetails.title}
                disabled={viewOnly}
                onChange={(event)=>{setsubscriberDetails({...subscriberDetails, title:event.target.value})}}
               >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Fr">Fr</option>
                  <option value="Other">other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={10} className="mb-3">
            Full Name
              <Form.Group id="fullname">
                <Form.Control required type="text" placeholder="Enter Full Name" value={subscriberDetails.subscriberName}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setsubscriberDetails({...subscriberDetails, subscriberName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="dateregistered">
              Date Registered
                <Form.Control required type="date" value={subscriberDetails.regDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, regDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
            Status
              <Form.Group className="mb-2">
                <Form.Control required type="text" placeholder="Status" value={subscriberDetails.subStatus}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, subStatus:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
            Start Date
              <Form.Group id="startdate">
                <Form.Control required type="date" value={subscriberDetails.startDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, startDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
            End Date
              <Form.Group className="mb-2">
                <Form.Control required type="date"  value={subscriberDetails.endDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, endDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>
                  
          <Row>
          <Col md={6} className="mb-3">
          Email Address
              <Form.Group id="email">
                <Form.Control required type="email" value={subscriberDetails.emailID}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col>
            Contact Number
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                
                <Form.Select name="areacode"
                                  value={subscriberDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code+item.Country} value={item.Code}>{item.Code}</option>);
                              })}
                  </Form.Select>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="phone">
                <Form.Control required type="number" placeholder="Enter your contact number" 
                                disabled={viewOnly}
                                value={subscriberDetails.contactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, contactNo:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
            Category
              <Form.Group id="category">
                <Form.Select value={subscriberDetails.categoryID}
                        disabled={viewOnly}
                        onChange={(event)=>{setsubscriberDetails({...subscriberDetails, categoryID:event.target.value})}}>
                  {journalCategory.map(item => {
                    return (
                    <option key={item.id} value={item.category}>{item.category}</option>
                    );
                  })}
            </Form.Select>              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            No. of Copies
              <Form.Group id="copies">
                <Form.Control required type="number" value={subscriberDetails.copies}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, copies:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col sm={6} className="mb-3">
            House Name
              <Form.Group id="hname">
                <Form.Control required type="text" value={subscriberDetails.houseName}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, houseName:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
            Address
              <Form.Group className="mb-2">
                <Form.Control required type="text" placeholder="Enter Full Address" value={subscriberDetails.addressStreet}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressStreet:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
            City
              <Form.Group id="hname">
                <Form.Control required type="text" value={subscriberDetails.addressCity}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressCity:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
            State
              <Form.Group className="mb-2">
                <Form.Control required type="text" placeholder="Enter State" value={subscriberDetails.addressState}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
            Pincode
              <Form.Group id="zip">
                <Form.Control required type="tel" placeholder="Postal Pincode" value={subscriberDetails.addressPincode}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
            Country
              <Form.Group id="country">
                <Form.Control required type="text" placeholder="Enter Country" value={subscriberDetails.country}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, country:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>\
          </Row>    
</Form>
</Card.Body>
</Card>
</div>

        </div>

    </div>
  )
}
