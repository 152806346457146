import React, { useState, useContext, useEffect } from 'react';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import signInImg from '../../images/logo-new.png';
import { ProductContext } from '../../ProductProvider';
import {Alert, Button } from "react-bootstrap";
import Preloader from '../../Components/preloader/Preloader';
import {Helmet} from 'react-helmet-async';
import './auth.css';
import FooterMain from '../FooterView/FooterMain';

export default function SignInMFA() {
    //let navigate = useNavigate();
    const { validateLogin, loggedUser, loginValidated, setLoginValidated, alertClose, alertDetails, setAlertDetails } = useContext(ProductContext)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPassword, setNewPassword]=useState('');
    //const [user, setUser] = useState("");
    //const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [loaded, setLoaded] = useState(true);


    useEffect(() => {
      alertClose();
      window.scrollTo(0,0);
      if(!loggedUser.isAuthenticated && !loginValidated){
        setLoginValidated(true);
        validateLogin();
      }
    }, [])

    const handleChangePassword =async(event)=>{
      event.preventDefault();
      try{
        setLoaded(false);
        //const attributes = {email: username};
         await confirmSignIn({
          challengeResponse: newPassword,
         })  
        .then(async(localeuser) => {
          if(localeuser.isSignedIn){
            await validateLogin();
           // enqueueSnackbar("Authenticated Successfully!",{variant:'success'});
            setLoaded(true);    
          }
  
          // at this time the user is logged in if no MFA required
         // console.log(localeuser);
        /* setLoggedUser({...loggedUser,

          name:localeuser.challengeParam.userAttributes['name'],
          email:localeuser.challengeParam.userAttributes['email'],
          profile:localeuser.challengeParam.userAttributes['profile'],
          DOB:localeuser.challengeParam.userAttributes['birthdate'],
          website:localeuser.challengeParam.userAttributes['website'],
          nickname:localeuser.challengeParam.userAttributes['nickname'],
          gender:localeuser.challengeParam.userAttributes['gender'],
          nickname:localeuser.challengeParam.userAttributes['family_name'],
          phone_number:localeuser.challengeParam.userAttributes['phone_number'],
          isAuthenticated:true,
         });
         setLoaded(true);
        navigate("/");*/
      }).catch(e => {
        setLoaded(true);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + e.message
        }); 
      });
    
      }catch(error){
        setLoaded(true);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
        }); 
      }
    }
    
    const handleSignIn = async(event) =>{
        event.preventDefault();
        try{
          if(email==="" || password===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Enter both Email and password to login!"
          }); 
          }else{
            //showLoader();
            setLoaded(false);
            const username = email;
            await signIn({username, password})
            .then(async(user)=>{
              //console.log("User : ", user);
              if(!user.isSignedIn && user.nextStep.signInStep==="CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"){
                //setUser(user);
               // setPasswordFocused(false);
                setShowNewPass(true);
                setAlertDetails({
                  ...alertDetails,
                  style:"info",
                  open:true,
                  message:"Set up your New password!"
              }); 

              //  enqueueSnackbar("Set up your New Password",{variant:'info'});
              }else{  
                await validateLogin();
             }
             setLoaded(true);
            /*  if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setUser(user);
                setShowNewPass(true);
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Set up your New password!"
              }); 
              }else{  
              setLoggedUser({...loggedUser,
                name:user.attributes['name'],
                email:user.attributes['email'],
                profile:user.attributes['profile'],
                DOB:user.attributes['birthdate'],
                website:user.attributes['website'],
                nickname:user.attributes['nickname'],
                gender:user.attributes['gender'],
                nickname:user.attributes['family_name'],
                phone_number:user.attributes['phone_number'],
                isAuthenticated:true,
                     });
               //hideLoader();
               setLoaded(true);
               if(actionClick){
                navigate("/publication");
               }else{
                navigate("/");
               }
              //history.push("/dashboard");
              }*/
          })
          .catch((error) =>{
            //hideLoader();
            setLoaded(true);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
        })
        }
        }catch(error){
          //hideLoader();
          setLoaded(true);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        }
    }

return (
  <>
      <Helmet>
      <title>Sign In - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Sign In - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Sign In - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
      </Helmet>

      {!loaded?<Preloader />:null}
      <div className="login" style={{paddingTop:"100px"}}>
      {/*<div style={{textAlign:"center"}}> {loader} </div>*/}
        <section  className="sign-in">
            <div className="container">
                <div className="signin-content">
                    <div className="signin-image">
                        <figure><img src={signInImg} alt="sign up"/></figure>
                       {/*} <Link to="/signup" className="signup-image-link">Create an account</Link>*/}
                    </div>
                    <div className="signin-form">
                    <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
                    {alertDetails.message}
                    </Alert>
                        <h2 style={{ fontFamily: "Oswald"}} className="form-title">Sign In</h2>
                      <form className="register-form" id="login-form">
                        <div  style={{ fontFamily: "Oswald"}} className="form-group"> 
                                
                                <div className="form-group">
                                
                                <label id="email"><i className="fa fa-envelope"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="email" name="email" id="email" placeholder="Your Email"
                                value={email} disabled={showNewPass}
                                onChange={(event)=>{setEmail(event.target.value)}}/>
                            </div>
                            
                            <div className="form-group">
                                <label id="pass"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="pass" id="pass" placeholder="Password"
                                value={password} disabled={showNewPass}
                                onChange={(event)=>{setPassword(event.target.value)}}/>
                            </div> {showNewPass?
                                <div className="form-group">
                                <label id="pass"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="pass" id="pass" placeholder="Enter New Password"
                                value={newPassword}
                                onChange={(event)=>{setNewPassword(event.target.value)}}/>
                            </div>:null}

                            <Link to='/forgot-password'><p>Forgot Password ? </p>   </Link>
                                   </div>
                           
                      {!showNewPass?<Button variant="primary" type="submit" className="btn w-100" onClick={(event)=>{handleSignIn(event)}}>
                        Sign in </Button>:
                      <Button variant="primary" type="submit" className="btn w-100" onClick={(event)=>{handleChangePassword(event)}}>
                        Change Password</Button>}
                            
                          {/*}  <button style={{ fontFamily: "Oswald", border:"1px solid blue"}} className="btn" onClick={(event)=>{handleSignIn(event)}}>Sign In</button> */}
                        
                        </form>
                      
                    </div>
                </div>
            </div>
        </section>
            </div>
            <FooterMain />
            </>
    )

}