import { useState, useContext, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { ProductContext } from '../../ProductProvider';
import { signOut } from 'aws-amplify/auth';
import logo from '../../images/logo.png';
import './Navbar3.css';

export default function Navbar3() {
    let navigate = useNavigate();
    const [loaded, setLoaded] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const toggleNavbar = (event) => {
     // console.log("event", event);
      //event.preventDefault();
      setCollapsed(!collapsed);
    }
  //const [showNavbar, setShowNavbar] = useState(false)
  const { loggedUser, setLoggedUser, alertClose} = useContext(ProductContext);

  useEffect(() => {
    alertClose();  
  }, [loggedUser])
  

  const handleLogOut = async(event)=>{
    event.preventDefault();
    try{
        setLoaded(false);
        await signOut()
        .then(() => {
            setLoggedUser({...loggedUser,
                username: '',
                isAuthenticated: false,
                email:'',
                phoneno:'',
                address:'',
                startDate:'',
                clientID:'',
                profile:'',
                orgName:''
            });
            //setAccountsFetched(false);
            //clearCart();
            setLoaded(true);
            navigate("/");
          })        
        }catch(error){
            setLoaded(true);
        //console.log("error ", error);
    }
}

return (
<div>
<nav>
  <div className="wrapper">
  <span >
    <NavLink className="navbar-brand" to="/" exact>
            <img src={logo} className='logo'/> 
    </NavLink>
    <NavLink className="navbar-brand" style={{color:"white"}} to="/" exact>
        Vidyajyoti Journal
    </NavLink>
  </span>
    {/*<span style={{display: "flex"}}><div className="logo">
        <NavLink className="navbar-brand" to="/" exact>
            <img src={logo} /> 
        </NavLink>
        </div>
        <div style={{alignItems:"left", marginTop:"0px"}}>
        <NavLink className="navbar-brand " style={{color:"white"}} to="/" exact>
        Vidyajyoti Journal
      </NavLink>
      </div>
      </span>*/}

    <input type="radio" name="slider" id='menu-btn' 
    //onChange={event=>toggleNavbar(event)}
    />
    <input type="radio" name="slider" id="close-btn" 
    //onChange={event=>console.log(event)}
    />
    <ul className="nav-links" style={{marginTop:"10px"}}>
      <label htmlFor='close-btn' className="btn close-btn" onClick={event=>toggleNavbar(event)}><i className="fas fa-times"></i></label>
      <li ><NavLink to="/">Home</NavLink></li>
      <li>              
        <NavLink to="/about">About</NavLink>
      </li>
      <li>
              <NavLink to="/subscription">Subscription</NavLink>
            </li>
            <li>
              <NavLink to="/publication">Publications</NavLink>
            </li>
            <li>
              <NavLink to="/archives">Archives</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            {!loggedUser.isAuthenticated ? 
            <li>
              <NavLink  to="/signin" exact>
              Sign In
              </NavLink></li>:
      <li>
        <a href="#" className="desktop-item">Hi {loggedUser.username}!</a>
        <input type="checkbox" id="showDrop"/>
        <label htmlFor="showDrop" className="mobile-item" style={{top:"unset"}}>Hi {loggedUser.username}!</label><span className='caret'></span> 
        <ul className="drop-menu">
          <li><NavLink to="/myprofile" >My Profile</NavLink></li>
          <li><NavLink to="#" onClick={(event)=>{handleLogOut(event)}} >Sign Out</NavLink></li>
        </ul>
      </li>}
     
    </ul>
    <label htmlFor={`${collapsed?'menu-btn':'close-btn'}`}  className="btn menu-btn" onClick={event=>toggleNavbar(event)} style={{top:"50%"}}><i className="fas fa-bars"></i></label>
  </div>
</nav>
    </div>
  )
}
