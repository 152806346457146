import React, { useEffect, useState } from 'react';
import axios from 'axios';
/*import slider1 from '../../images/images/slider1.jpg';
import slider2 from '../../images/images/slider2.jpg';
import slider3 from '../../images/images/slider3.jpg';
import mobslider1 from '../../images/images/mob-slider1.jpg';
import mobslider2 from '../../images/images/mob-slider2.jpg';
import mobslider3 from '../../images/images/mob-slider3.jpg';
import mobslider4 from '../../images/images/mob-slider4.jpg';
import mobslider5 from '../../images/images/mob-slider5.jpg';
import mobslider6 from '../../images/images/mob-slider6.jpg';*/
//import issue from '../../images/Issues/febIssue.jpg';
import Preloader from '../preloader/Preloader';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slider.css";
import { Carousel } from 'react-responsive-carousel';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../images/logo-new.png';
const config = require('../../config.json');


export default function SliderMain () {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [poster, setPoster] = useState(logo);

  useEffect(()=>{
    fetchSliderImages();
    fetchPosterImages();
   // console.log("props scrollInfo :", scrollInfo);
  },[])

  const fetchSliderImages =async()=>{
    try{
       // console.log("Testing");
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      const fileName='images/slider/';
      const bucketName="images.vidyajyotijournal.com";

    await axios.get(`${config.api.invokeUrl}/images/slider?fileName=${fileName}&bucketName=${bucketName}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
      const finalList=[];
      //console.log("Res : ", response);
      if(response.data.length>0){
        for (const i in response.data){
          const tValue=response.data[i];
          const tPathName=tValue.split("/");
          const tBreakUp=tPathName[2];
          const tempValue={};
          tempValue.fullPathName=response.data[i];
          tempValue.fileName=tBreakUp;
          finalList.push(tempValue);
        }
      }
        //console.log("Res : ", finalList);
       // console.log("test :", config.s3.invokeUrl+finalList[0].fullPathName);
        setGalleryImages(finalList);
        setLoaded(true);
        //setDispMsg(response.data.length + " - Images are found in your Gallery");
    }).catch((error)=>{
        setLoaded(true);
        console.log(error.message);
        //setDispMsg(error.message);
    })
}catch(error){
  setLoaded(true);
  console.log(error.message);
  //setDispMsg(error.message);
}
  }

  const fetchPosterImages =async()=>{
    try{
       // console.log("Testing");
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      const fileName='images/poster/';
      const bucketName="images.vidyajyotijournal.com";

    await axios.get(`${config.api.invokeUrl}/images/slider?fileName=${fileName}&bucketName=${bucketName}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
      const finalList=[];
      //console.log("Res : ", response);
      if(response.data.length>0){
        for (const i in response.data){
          const tValue=response.data[i];
          const tPathName=tValue.split("/");
          const tBreakUp=tPathName[2];
          const tempValue={};
          tempValue.fullPathName=response.data[i];
          tempValue.fileName=tBreakUp;
          finalList.push(tempValue);
        }
      }
        //console.log("Res : ", finalList);
        setPoster(finalList[0].fullPathName);
       // console.log("test :", config.s3.invokeUrl+finalList[0].fullPathName);
        //setGalleryImages(finalList);
        setLoaded(true);
        //setDispMsg(response.data.length + " - Images are found in your Gallery");
    }).catch((error)=>{
        setLoaded(true);
        //console.log(error.message);
        //setDispMsg(error.message);
    })
}catch(error){
  setLoaded(true);
  //console.log(error.message);
  //setDispMsg(error.message);
}
  }

  return (
  <>
    {!loaded && <Preloader show={loaded ? true : false} />}
    <div >
      <Row style={{marginLeft:"0px", marginRight:"0px"}}>
        <Col md={8}>
        <Carousel  autoPlay={loaded} showThumbs={false} infiniteLoop showArrows showStatus showIndicators  >
        {galleryImages.length>0 && galleryImages.map((images, index)=>  
        <div key={index}>
          <img className='img-fluid' key={index} alt="slider" src={encodeURI(`${config.s3.imageInvokeURL}${images.fullPathName}`)} style={{padding:"10px", width:"100%", borderRadius:"30px"}}/>
        </div>)}
               {/*} <div>
                    <img src={slider2} alt='slider'/>
                    </div>
               {/*   <div className='slider-cap-wrap'  >
                 <div className="card container caption" >
                      <h2>SITE &nbsp; LAND DEVELOPMENTS</h2>
                      <p>Develop your existing land with us and build into residential apartments, 
                          commercial spaces and much more</p>
                  </div>  
                  <button className='btn-caption' >Know More</button>
                  </div> */}
                {/*} <div>
                      <img src={slider3}  alt='slider' />    
                </div>*/}       
            </Carousel>
        </Col>
        <Col md={4}>
          <Link to="/publication"><img src={encodeURI(`${config.s3.imageInvokeURL}${poster}`)} style={{padding:"20px", width:"100%", borderRadius:"40px"}}/></Link>
        </Col>
      </Row>
          {/*   <Carousel className='navbar-mob' autoPlay={true} showThumbs={false} infiniteLoop={true}  >
      {galleryImages.map((images, index)=>  
      <div >
        <img className='img-fluid' key={index} alt="slider" src={encodeURI(`${config.s3.imageInvokeURL}${images.fullPathName}`)} style={{width:"100%", height:"100%"}}/>
      <div className='slider-cap-wrap' >
      </div>
      </div>)}
               {/*} <div>
                    <img src={slider2} alt='slider'/>
                    </div>

               {/*   <div className='slider-cap-wrap'  >
                        
                 <div className="card container caption" >
                      <h2>SITE &nbsp; LAND DEVELOPMENTS</h2>
                      <p>Develop your existing land with us and build into residential apartments, 
                          commercial spaces and much more</p>
                      
                  </div>  
                  <button className='btn-caption' >Know More</button>
  </div> */}
               {/*} <div>
                    <img src={slider3}  alt='slider' />
                   
</div>
                    
                    
            </Carousel>
           <Carousel className='navbar-mob' autoPlay={true} showThumbs={false} infiniteLoop={true}  >
                <div >
                    <img className='img-fluid' src={mobslider1} alt='slider' />
                    <div className='slider-cap-wrap' >
                        
                        
           </div>
            </div>
                <div>
                    <img src={mobslider1} alt='slider'/>
              
                   </div>
                <div>
                    <img src={mobslider2}  alt='slider' />
                   
                      </div>
                      <div>
                    <img src={mobslider3}  alt='slider' />
                   
                      </div>
                      <div>
                    <img src={mobslider4}  alt='slider' />
                   
                      </div>
                      <div>
                    <img src={mobslider5}  alt='slider' />
                   
                      </div>
                      <div>
                    <img src={mobslider6}  alt='slider' />
                      </div>
            </Carousel>*/}
    </div>
    </>
    )
  
}




