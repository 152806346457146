import React, { useEffect } from 'react';
import SliderMain from '../../Components/Slider/SliderMain';
import Contact from '../Contact/Contact';
import Subscription from '../Subscription/Subscription';
import ViewMagazine1 from '../Journal/ViewMagazine1';
import FooterMain from '../FooterView/FooterMain';
import {Helmet} from 'react-helmet-async';

const Home = () => {

  useEffect(() => {
   // alertClose();
    window.scrollTo(0,0);
  }, [])

  return (
    <div   >
    <Helmet>
      <title>VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
    <SliderMain/>
    <div className='home-sections'>
        <ViewMagazine1 />
        <Subscription/>
    </div>
    <Contact />
    <FooterMain/>
  </div>
  )
}

export default Home;