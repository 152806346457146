import React,{useEffect} from 'react';
import FooterMain from '../FooterView/FooterMain';

export default function Disclaimer() {

  useEffect(() => {
    // alertClose();
     window.scrollTo(0,0);
   }, [])

  return (
    <>
    <div>
        <h2 className="heading-1 mt-3">Disclaimer Policy</h2>     

<p style={{textAlign: "justify"}}>The information contained on this website is for educational purposes only. The views published in Vidyajyoti Journal of Theological Reflection are those of the authors and not necessarily of the Editorial Board or of the Faculty of Vidyajyoti College of Theology. While we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>

<p style={{textAlign: "justify"}}>In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>

<p style={{textAlign: "justify"}}>Through this website, you can link to other websites that are not under the control of the Vidyajyoti. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>

<p style={{textAlign: "justify"}}>Every effort is made to keep the website up and running smoothly. However, the Vidyajyoti takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
    <p align="right">Regards,</p>

    <p align="right"><b>Board of Editors</b>, Vidyajyoti</p>
    
    <p align="right"><b>https://www.vidyajyotijournal.com/</b></p>

    </div>
    <FooterMain />
    </>
  )
}
