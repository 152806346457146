import React from 'react';
import {Table} from 'react-bootstrap';
//import FooterMain from '../FooterView/FooterMain';


const Subscription = () => {
  
  return (
  <div className='subscription-section p-3 text-center ' >

    <h3 >Subscription Details</h3>
<h5 > INFORMATION ABOUT SUBSCRIPTION </h5>
<div >
{/*<ul className='text-justify'  style={{listStyle:"none"}}>
  <li className='p-3'>
* Subscription Rates: India (Rs. 250); Bangla Desh, Pakistan, Nepal & Sri Lanka (Surface Mail: INR 1000/ USD 20; Air Mail: INR 1500/ USD 25); Other Countries (No Surface Mail; Air Mail US $ 60). 
  </li>
  <li className='p-3'>
* Subscriptions from within India may be paid by Bank Transfer facility or by Cheque/Demand Draft payable at Delhi made in favour of VIDYAJYOTI JOURNAL. Please write only VIDYAJYOTI JOURNAL in the place for “pay to” in the Cheque/DD.  We have discontinued the practice of payment by Money Order.  For Bank Transfer details, please see below.
  </li>
  <li className='p-3'>
* Subscriptions from foreign countries are to be paid by Bank Transfer facility only. Payment by Cheque/Demand Draft from foreign countries have been discontinued. For Bank transfer details please, see below.
  </li>
  </ul>*/}
<p className='p-1'>
  <Table responsive hover striped bordered >
    <thead>
      <th>Subscription Format</th>
      <th>Indian</th>
      <th>Foreign</th>
      <th>*SAARC Countries</th>
    </thead>
    <tbody>
      <tr>
        <td>Hard Copy + Online</td>
        <td>Rs.250</td>
        <td>US $60</td>
        <td>US $25</td>
      </tr>
      <tr>
        <td>Only Online</td>
        <td>Rs. 150</td>
        <td>US $30</td>
        <td>US $15</td>
      </tr>
    </tbody>
  </Table>
  </p>
</div>
<p className='p-2'>
<li className='p-3'>
<b>Subscriptions from within India</b> may be paid by <b>Bank Transfer</b> facility or by Cheque/Demand Draft payable at Delhi made in favour of <b>VIDYAJYOTI JOURNAL</b>. Please write only VIDYAJYOTI JOURNAL in the place for “pay to” in the Cheque/DD.  We have discontinued the practice of payment by Money Order.  For Bank Transfer details, please see below.
  </li>
  </p>
  <div className='card'>
  <p className='p-2'>
    <Table responsive striped bordered hover>
      <thead>
        <th>Payment Details</th>
        <th>Payment from India</th>
        <th>Payment from Other Countries</th>
      </thead>
      <tbody>
        <tr>
          <td>Name of the Bank</td>
          <td>Canara Bank</td>
          <td>State Bank of India</td>
        </tr>
        <tr>
          <td>Name of the Branch</td>
          <td>Rajniwas Marg (St. Xavier’s School) Branch, 
					New Delhi</td>
          <td>New Delhi Main Branch, New Delhi-110001</td>
        </tr>
        <tr>
          <td>Account Holder’s Name</td>
          <td>Vidyajyoti Journal</td>
          <td>Jiv Prakash Vidyapeeth</td>
        </tr>
        <tr>
          <td>Account Number</td>
          <td>91152010007627</td>
          <td>40083282569</td>
        </tr>
        <tr>
          <td>IFSC Code</td>
          <td>CNRB0019115</td>
          <td>-</td>
        </tr>
        <tr>
          <td colSpan={3}>For further info: Contact vjtr75@gmail.com </td>
        </tr>
      </tbody>
    </Table>
    </p>
    <p className='p-2'>
    Include your <b>subscription number</b> in the Bank remittances slip. After making the remittances please write to us giving the details (name of the bank, date of remittance, amount, payment reference number, etc.). We appreciate correspondence through e-mail to <b>vjtr75@gmail.com</b> or you may use the postal address: <b>The Publisher, Vidyajyoti Journal, 4A Rajniwas Marg, Delhi – 110 054, India.</b>
    </p>
  </div>
  </div>
  )
}
export default Subscription;