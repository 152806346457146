import React,{useState, useEffect, useContext} from 'react';
//import {Helmet} from "react-helmet-async";
import Preloader from "../../Components/preloader/Preloader";
import { ProgressBar } from '@react-pdf-viewer/core';
import { ProductContext } from '../../ProductProvider';
//import {Row, Col} from 'react-bootstrap';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Modal, Button} from 'react-bootstrap';
import CardTemplate from '../Articles/CardTemplate';
import newicon from '../../images/new-gif.gif';
//import arti from '../../images/articles.png';
// Import the main component
import {Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
//import { searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
//import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
const writingGif=require("../../images/background/writing.gif");
const config = require('../../config.json');


export const ViewMagazine1 = () => {
    let navigate = useNavigate();
    const { alertDetails, setAlertDetails, loggedUser, setActionClick } = useContext(ProductContext);
  const [selectedFile, setSelectedFile]=useState(null);
  const [loaded, setLoaded] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [pdfJournalDetails, setPDFJournalDetails] = useState([]);
  const [fullIssueDetails, setFullIssueDetails] = useState([]);
  const [pdfFile, setPdfFile]=useState(null);
  const [showPDF, setShowPDF]=useState(false);
  const fontColor=`rgba(22, 22, 117, 0.941)`;
  const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  useEffect(() => {
    fetchJournalDetails();   
  }, [])

  const fetchJournalDetails=async()=>{
    try{
      setLoaded(false);
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      //const clientID="VJJournal";
      const searchOption="JournalStatus";
      const searchValue="Active";
      await axios.get(`${config.api.invokeUrl}/journal/static?searchOption=${searchOption}&searchValue=${searchValue}`,{
          headers: {
            //Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        //console.log("Journal Response : ", response);
        const responseData=response.data;
        setPDFJournalDetails(response.data);
        const tempBookReview = responseData.filter(item => item.journalCategory ==="Book Review");
        const tempEditorials = responseData.filter(item => item.journalCategory ==="Editorial Section");
        const tempArticles = responseData.filter(item => item.journalCategory ==="Articles");
        const tempFullIssues = responseData.filter(item => item.journalCategory ==="Full Issue");
        //setEditorialDetails(tempEditorials);
        //setArticleDetails(tempArticles);
        //setReviewDetails(tempBookReview);
        setFullIssueDetails(tempFullIssues);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - " + searchOption + " Journals Found"
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          //console.log(error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          //setDispMsg(error.message);
      })
  }catch(error){
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
  }

  const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      //EnterFullScreen: () => <></>,
      //EnterFullScreenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
  });

const handlePageChange = (e) => {
  localStorage.setItem('current-page', `${e.currentPage}`);
};

const handleSwitchTheme = (theme) => {
  localStorage.setItem('current-theme', theme);
};

const handleDocumentLoad=(e)=>{
  setLoaded(true);
}

  const handleBack=(e)=>{
    e.preventDefault();
    setPdfFile('');
    setShowPDF(false); 
  }
  
  const handleFetchPDF = async(e, item) => {
    e.preventDefault();
    //console.log("fetch : ", item);
    try{
        if(item.journalCategory==="Book Review"|| item.journalCategory==="Editorial Section"){
            const fetchFile=item.journalID+".pdf";
            //console.log("fetch file :", fetchFile);
            const bucketName='docs.vidyajyotijournal.com/pdfs';
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
            setSelectedFile(item);
            await axios.get(`${config.api.invokeUrl}/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}`,{
                headers: {
                //Authorization: access_token,
                'x-api-key': config.api.key
                }}, 
            ).then(async(response)=>{
                //console.log("Response : ", response);
                setPdfFile(response.data.downloadURL);
                setShowPDF(true); 

                /*await axios.get(response.data.downloadURL,{
                    responseType:'arraybuffer',
                    headers: {
                    "Content-Type": "application/json",
                    "Accept":"application/pdf",
                    //"content-disposition": "attachment; filename=test.pdf",
                    "access-control-allow-origin" : "*",
                    }
                })
                .then(function(pdfresponse){
                console.log("pdf response : ", pdfresponse);
                let blob = new Blob([pdfresponse.data], {type: 'application/pdf'});
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                console.log("link.href : ", link);
                // setPdfFile(pdfresponse.data);
                // setShowPDF(true); 
        
                })*/
            }).catch((error)=>{
                console.log("Error1 : ", error);
                //setDispMsg("Error Occured - ", error.message);
            })  
        }else if(!loggedUser.isAuthenticated && (item.journalCategory==="Articles"||item.journalCategory==="Full Issue")){
            setActionClick(item);
            navigate("/signin");
        }else if(loggedUser.isAuthenticated && (item.journalCategory==="Articles"||item.journalCategory==="Full Issue")){
            const fetchFile=item.journalID+".pdf";
            //console.log("fetch file :", fetchFile);
            const bucketName='docs.vidyajyotijournal.com/pdfs';
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            setSelectedFile(item);
            await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
                headers: {
                Authorization: idToken,
                'x-api-key': config.api.key
                }}, 
            ).then(async(response)=>{
                //console.log("Response : ", response);
                setPdfFile(response.data.downloadURL);
                setShowPDF(true); 
            }).catch((error)=>{
                console.log("Error1 : ", error);
            })  
        }    
    }catch(error){
        console.log("Error2 : ", error);
    }
}

  return (
    <>
    {!loaded && <Preloader show={loaded ? true : false} />}
    <Modal
        show={showPDF}
        onHide={() => setShowPDF(false)}
        fullscreen={showPDF}
        size="xl"
        backdrop="static"
        keyboard={false}
        scrollable
        style={{top:5, zIndex:3999}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          <div onClick={event=>{handleBack(event)}}>
            <FontAwesomeIcon icon={faBackward} onClick={event=>{handleBack(event)}}/> Go Back</div>
            {showPDF && selectedFile.journalName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundImage: `url(' + magazineBackground + ')`}}>
        {showPDF && <div>     
     {progressValue<100 ?<div  className='justify-content-between' >
              <p style={{ alignItems:'center'}}>Loading... Please Wait...</p>
                <ProgressBar progress={progressValue} />
                <img src={writingGif} alt="Writing Gif"/>
            </div>:null}
        {showPDF &&  pdfFile && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          <Viewer fileUrl={pdfFile}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}
          renderLoader={(percentages) => (
            setProgressValue(Math.round(percentages))
        )}
        />
      </Worker>}
    </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleBack}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>
    <div style={{ backgroundImage: `url(' + magazineBackground + ')` , backgroundRepeat:"repeat"}}>
    <section id="speakers">
        <div className='pt-4 text-center' data-aos="fade-up" >
        <div className='d-flex justify-content-around section-title' >
          <div style={{borderRadius:"50px"}}>
            {pdfJournalDetails.length>0 && <div ><img className='new-icon' src={newicon} />
            <h3 style={{color:`${fontColor}`}}>Latest Issue - {new Date(pdfJournalDetails[0].publishDate).toLocaleDateString('en-us', { year:"numeric", month:"long"})} </h3>
          </div> }
          </div> 

            {fullIssueDetails.length>0?
            <button onClick={event=>handleFetchPDF(event, fullIssueDetails[0])} className='btn-primary p-2' style={{backgroundColor:"brown", color:"white", borderRadius:"20px",cursor:"pointer"}}>
              <h3 style={{color:"white", fontSize:"18px"}}>To read <br/>the entire issue <i className='fa fa fa-book'></i></h3>
          </button>:null}
        </div>
           <div className='card p-3'>
            {pdfJournalDetails.length>0 ?<CardTemplate  displayDetails={pdfJournalDetails}  handleFetchPDF={handleFetchPDF}/>:null}
            </div>
        </div>
    </section>
    <div style={{padding:30}}>
    <p>To Authors : Articles for publication, not published elsewhere, may be sent to vjtr75@gmail.com. Please limit the number of words to 6000. Books for review and exchange copies of periodicals should be addressed to: The Editor, Vidyajyoti Journal, 4A Rajniwas Marg, Delhi – 110 054, India.</p>
    </div>
    </div>
    </>
  )
}

export default ViewMagazine1;