import React, { useContext, useState } from 'react';
import {Row, Col}  from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ProductContext } from '../../ProductProvider';
import sp1 from '../../images/publications/1.jpg';
import sp2 from '../../images/publications/2.jpg';
import sp3 from '../../images/publications/3.jpg';
import sp4 from '../../images/publications/4.jpg';
import sp5 from '../../images/publications/5.jpg';
import sp6 from '../../images/publications/6.jpg';
import sp7 from '../../images/publications/7.jpg';
import sp8 from '../../images/publications/8.jpg';
import sp9 from '../../images/publications/9.jpg';
import sp10 from '../../images/publications/10.jpg';
import ViewJournal from '../Journal/ViewJournal';
import ViewMagazine1 from '../Journal/ViewMagazine1';
//import FooterMain from '../FooterView/FooterMain';

const decmainview=[{img:[sp1], header:"A Warli-Christian Story", author: "Wendell D’Cruz SJ", publisher:"Vidyajyoti/ISPCK", pages:"70", publishyear:"1999", price:"₹55", IntPrice:"$6"},
                    {img:[sp2], header:"Body, Bread, Blood", author: "Francis Gonsalves SJ", publisher:"Vidyajyoti/ISPCK", pages:"312", publishyear:"2000", price:"₹110", IntPrice:"$14"},
                    {img:[sp3], header:"Seeking New Horizons", author: "Leonard Fernando SJ", publisher:"VIEWS/ISPCK", pages:"356", publishyear:"2002", price:"₹170", IntPrice:"$14"},
                    {img:[sp4], header:"Co-worker for Your Joy", author: "S. Painadath SJ & Leonard Fernando SJ", publisher:"VCT/ISPCK", pages:"336", publishyear:"2006", price:"₹150", IntPrice:"$11"},
                    {img:[sp5], header:"Taking Text to Context", author: "George Keerankeri SJ & V. P. Srivastava", publisher:"VCT/ISPCK", pages:"315", publishyear:"2011", price:"₹220", IntPrice:"$14"},
                    {img:[sp6], header:"An Eye For An Eye. A Tooth for a Tooth", author: "Milianus Beck SJ", publisher:"VCT/ISPCK", pages:"412", publishyear:"2006", price:"300", IntPrice:"$19"},
                    {img:[sp7], header:"Christian Faith Meets Other Faiths", author: "Leonard Fernando SJ", publisher:"VIEWS/ISPCK", pages:"261", publishyear:"1999", price:"₹55", IntPrice:"$6"},
                    {img:[sp8], header:"Globalization and Its Victims", author: "Wendell D’Cruz SJ", publisher:"Vidyajyoti/ISPCK", pages:"70", publishyear:"1999", price:"₹55", IntPrice:"$6"},
                    {img:[sp9], header:"Gems from India", author: "Wendell D’Cruz SJ", publisher:"Vidyajyoti/ISPCK", pages:"70", publishyear:"1999", price:"₹55", IntPrice:"$6"},
                    {img:[sp10], header:"Dalit World - Biblical World. An Encounter", author: "Wendell D’Cruz SJ", publisher:"Vidyajyoti/ISPCK", pages:"70", publishyear:"1999", price:"₹55", IntPrice:"$6"}
];

const Publications = () => {
  const navigate=useNavigate();
  const [JournalView, setJournalView] = useState(false);
  const { loggedUser} = useContext(ProductContext);


  const handleMagView=(event, item)=>{
    event.preventDefault();
    try{
      //console.log("ITem : ", item);
      navigate("/contact");

    /*  if(loggedUser.isAuthenticated){
        setJournalView(true);
      }else {
        history.push("/signin");
      }*/
    }catch(error){
      console.log("Error : ", error);
    }
  }
  
  return (
  <div className="p-3">
    
<ViewMagazine1/>
    <section id="speakers">
      <div className="" data-aos="fade-up">
        <div className="section-header text-center">
          <h4 >Book Publications</h4>
          <p>Here are some of our Book Publications</p>
        </div>
    <div className="row ">  
    <div className='p-3 m-3 text-center'>
      <Row>
     {decmainview.map((item,index)=>
        <Col key={index} md={3} className='text-center card p-2' style={{border:"5px solid #07096f", borderRadius:"8px"}}>
             <div className="pub-image" data-aos="fade-up" data-aos-delay="100">
              <img src={item.img} alt="Publications" className="pub-image img-fluid rounded" />
            </div>
         <div className='subscription-details'>
            <h5 className="card-title">{item.header}</h5>
                <p>Author: {item.author}</p>
                <p>Publisher: {item.publisher}</p>
                <p>Number of Pages: {item.pages}</p>
                <p>Publishing Year:{item.publishyear}</p>
                <p>Indian Price {item.price} </p> 
                <p>Intl. Price {item.IntPrice}</p>
                <div className="social">
                  <a href=""><button className='btn-secondary rounded p-2 btn-wrap' onClick={event=>handleMagView(event,item)}> Know More </button></a>  
                </div>
              </div>
        </Col>       
           )}
          </Row>     
          </div>
        </div>

        {JournalView ? <ViewJournal />:null}
      </div>
    </section>
  </div>
  )
}
export default Publications;