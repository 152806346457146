import React,{useEffect} from 'react';
import AboutUs from './AboutUs';
import FooterMain from '../FooterView/FooterMain';

export default function AboutMain() {

  useEffect(() => {
    // alertClose();
     window.scrollTo(0,0);
   }, [])

  return (
    <div>
        <AboutUs />
        <FooterMain />
    </div>
  )
}
