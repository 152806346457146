import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import signInImg from '../../images/logo-new.png';
import Preloader from '../../Components/preloader/Preloader';
import { Alert, Button } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function ForgotPassword() {
    //const [dispMsg, setDispMsg] = useState('');
    const navigate = useNavigate();
    const { loggedUser, loginValidated, setLoginValidated, validateLogin, alertClose, alertDetails, setAlertDetails } = useContext(ProductContext)
    //const [code, setCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [username, setUsername]= useState('');
    const [confirmationCode, setConfirmationCode]= useState('');
    const [newPassword, setNewPassword] = useState('');
    const [nextPage, setNextPage]=useState(false); 

    useEffect(() => {
        if(!loggedUser.isAuthenticated && !loginValidated){
          setLoginValidated(true);
          validateLogin();
        }
      }, [])
    

    /*const handleForgotPassword = async(event) =>{
        event.preventDefault();
        try{
            if(email===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Enter Registered Email Address!"
                }); 
      
                //setDispMsg("Enter Registered Email Address!");
            }else{
                setLoaded(false);
            await Auth.forgotPassword(email)
            .then(()=>{
                setCodeSent(true);
                //setDispMsg("OTP has been sent to your registered Email address");
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message:"OTP has been sent to your registered Email address"
                }); 
                setLoaded(true);
            })
            .catch((error)=>{
                setLoaded(true);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                //setDispMsg(`Error Occured : ${error.message}`);
            });
        }
        }catch(error){
            setLoaded(true);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 

           // setDispMsg(`Error Occured : ${error.message}`);
        }
    }*/

    const handleSendAuthEmail=async(event)=>{
        event.preventDefault();
        try {
          if(username===""){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Enter Registered Email Address!"
            }); 
          }else if(!username.match(validRegex)){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Enter Registered Email Address!"
            }); 
         }else{
          setLoaded(false);
          const output = await resetPassword({ username });
          //console.log("Output : ", output);
          setAlertDetails({
            ...alertDetails,
            style:"info",
            open:true,
            message:"Password Reset initiated!"
        }); 
          handleResetPasswordNextSteps(output);
          setLoaded(true);
          }
        } catch (error) {
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });           
            setLoaded(true);
        }  
       // setNextPage(true);
      }

      function handleResetPasswordNextSteps(output) {
        setLoaded(false);
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
          case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
            const codeDeliveryDetails = nextStep.codeDeliveryDetails;
            setAlertDetails({
                ...alertDetails,
                style:"info",
                open:true,
                message:`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
            });   
            //enqueueSnackbar(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,{variant:'info'});
            setNextPage(true);
            setLoaded(true);
            // Collect the confirmation code from the user and pass to confirmResetPassword.
            break;
          case 'DONE':
            setAlertDetails({
                ...alertDetails,
                style:"info",
                open:true,
                message:'Password Reset Successful!'
            });   
            //enqueueSnackbar('Password Reset Successful!',{variant:'info'});
            setLoaded(true);
            break;
        }
      }
    
      const handleConfirmResetPassword =async(event)=>{
        event.preventDefault();
        try {
          if(confirmationCode===""){
            setAlertDetails({
                ...alertDetails,
                style:"info",
                open:true,
                message:"Enter your code sent to your registered eamil address!"
            });           
          }else if(newPassword===""){
            setAlertDetails({
                ...alertDetails,
                style:"info",
                open:true,
                message:"Enter your new Password!"
            });           
          }else if(username===""){
            setAlertDetails({
                ...alertDetails,
                style:"info",
                open:true,
                message:"Enter your registered eamil address!"
            });           
          }else{
          setLoaded(false);
          await confirmResetPassword({ username, confirmationCode, newPassword })
          .then((res)=>{
            setAlertDetails({
                ...alertDetails,
                style:"info",
                open:true,
                message:"Your Password has been changed successfully! Login back with your New Password!"
            });           
          setLoaded(true);
          navigate("/signin");
          })
          .catch((error)=>{
            setLoaded(true);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });           
          })
        }
        } catch (error) {
          setLoaded(true);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });           
        }  
      }

    /*const handleVerifyForgotPassword = async(event) =>{
        event.preventDefault();
        try{
            if(email==="" || code==="" || password===""){
                //setDispMsg("Enter all the details!");
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Enter all the Details!"
                }); 

            }else{
            const username=email;
            const new_password=password;
            await Auth.forgotPasswordSubmit(username, code, new_password)
            .then(() => {
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message:"Password Changed Successfully"
                }); 
                //setDispMsg("Password Changed Successfully")
            })
            .catch(err => {
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + err.message
                }); 
                //setDispMsg(`Error Occured : ${err.message}`)
            });
        }
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 

            //setDispMsg(`Error Occured : ${error.message}`);
        }
    }*/

    return (
    <>
    <Helmet>
      <title>Forgot Password - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Forgot Password - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Forgot Password - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

        <div className="login">
            {!loaded?<Preloader />:null}
            <section  className="sign-in">
            <div className="container">
                <div className="signin-content">
                    <div className="signin-image">
                        <figure><img src={signInImg} alt="sign up"/></figure>
                       {/*} <Link to="/signup" className="signup-image-link">Create an account</Link>*/}
                    </div>

                    <div className="signin-form">
                    <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
                    {alertDetails.message}
                    </Alert>

                        <h2 style={{ fontFamily: "Oswald"}} className="form-title">Forgot Password</h2>
                        <form className="register-form" id="login-form">
                        <div  style={{ fontFamily: "Oswald"}} className="form-group"> 
                            <div className="form-group">    
                                <label id="email"><i className="fa fa-envelope"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="email" name="email" id="email" placeholder="Your Email"
                                value={username}
                                onChange={(event)=>{setUsername(event.target.value)}}/>
                            </div>

                            {codeSent?<div>
                            <div className="form-group">
                                
                                <label id="code"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="text" name="code" id="code" placeholder="Enter Code"
                                value={confirmationCode}
                                onChange={(event)=>{setConfirmationCode(event.target.value)}}/>
                            </div>
                            <div className="form-group">
                                
                                <label id="password"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="password" id="password" placeholder="New Password"
                                value={newPassword}
                                onChange={(event)=>{setNewPassword(event.target.value)}}/>
                            </div>
                            </div>:<div></div>}
                            

                            <Link to="/signin"><p> Click here to Sign In</p></Link>
                                   </div>
                           
                            {!codeSent?<Button variant="primary" type="submit" className="btn w-100" onClick={(event)=>{handleSendAuthEmail(event)}}>Generate Code</Button> 
                            :<Button variant="primary" type="submit" className="btn w-100" onClick={(event)=>{handleConfirmResetPassword(event)}}>Reset Password</Button> }  
                        </form>
                       </div>
                </div>
            </div>
        </section>
    </div>
    </>
    )

}